<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <div class="slider">
    <div class="bkg" :style="`width:${assets.bkgWidth}px;`">
      <img :src="assets.bkg" alt="Background">
      <div class="text-history"><div v-html="$t('ecommerceStoryline.vTitle')" /></div>
      <div class="img-base img-photo"><img :src="assets.image" alt="Static photo"></div>

      <div class="label label-y label-photo"><p>{{ $t('ecommerceStoryline.photo.title') }}</p></div>
      <div class="label label-y" :style="`right: ${3093-rightCorrection}px;`"><p>2002</p></div>
      <PopoverPlate :x="591" :placement="stdPlacement" :title="$t('ecommerceStoryline.photo.title')">
        <p>{{ $t('ecommerceStoryline.photo.description') }}</p>
      </PopoverPlate>
      
      <div class="img-base img-carousel">
        <video autoplay muted loop="true" playsinline>
          <source :src="assets.carousel" type="video/mp4">
          {{ $t('error.browserNoVideoTag') }}
        </video>
      </div>
      <div class="label label-y label-carousel"><p>{{ $t('ecommerceStoryline.carousel.title') }}</p></div>
      <div class="label label-y" :style="`right: ${2555-rightCorrection}px;`"><p>2004</p></div>
      <PopoverPlate :x="1129" :placement="stdPlacement" :title="$t('ecommerceStoryline.carousel.title')">
        <p>{{ $t('ecommerceStoryline.carousel.description') }}</p>
      </PopoverPlate>
      
      <div class="img-base img-360">
        <video autoplay muted loop="true" playsinline>
          <source :src="assets.photo360" type="video/mp4">
          {{ $t('error.browserNoVideoTag') }}
        </video>
      </div>
      <div class="label label-y label-360"><p v-html="$t('ecommerceStoryline.photo360.vTitle')" /></div>
      <div class="label label-y" :style="`right: ${2019-rightCorrection}px;`"><p>2012</p></div>
      <PopoverPlate :x="1666" :placement="stdPlacement" :title="$t('ecommerceStoryline.photo360.title')">
        <p>{{ $t('ecommerceStoryline.photo360.description') }}</p>
      </PopoverPlate>

      
      <div class="img-base img-viewer-3d">
        <video autoplay muted loop="true" playsinline>
          <source :src="assets.viewer3d" type="video/mp4">
          {{ $t('error.browserNoVideoTag') }}
        </video>
      </div>
      <div class="label label-y label-3d-viewer label-light"><p v-html="$t('ecommerceStoryline.viewer3d.vTitle')" /></div>
      <div class="label label-y label-light" :style="`right: ${1475-rightCorrection}px;`"><p>2016</p></div>
      <PopoverPlate :x="2203" :placement="stdPlacement" :title="$t('ecommerceStoryline.viewer3d.title')">
        <p>{{ $t('ecommerceStoryline.viewer3d.description') }}</p>
      </PopoverPlate>
      
      <div class="img-base img-ar" :style="`width: ${assets.arWidth}px;`">
        <video autoplay muted loop="true" playsinline>
          <source :src="assets.ar" type="video/mp4">
          {{ $t('error.browserNoVideoTag') }}
        </video>
      </div>
      <div class="label label-ar label-light"><p>{{ $t('ecommerceStoryline.ar.title') }}</p></div>
      <PopoverGroup>
        <PopoverPlate :x="2727" :placement="Placement.bottom" :title="$t('ecommerceStoryline.ar.titleExtended')">
          <p>{{ $t('ecommerceStoryline.ar.description') }}</p>
        </PopoverPlate>
        <PopoverPlate :x="2727" :y="215" :width="54" :height="54" :placement="Placement.right" panelStyle="top: 80px;" 
          :title="$t('ecommerceStoryline.ar.qr.title')"
          >
          <template #image>
            <img class="absolute top-0" :src="assets.qr">
          </template>
          <p>{{ $t('ecommerceStoryline.ar.qr.cta') }}</p>
          <div style="width: 252px;" class="pt-6">
            <a :href="assets.viewerUrl" target="_blank" :title="assets.viewerLinkTitle">
              <qrcode-vue :size="252" level="H" :margin="0" class="w-full h-fit"
                :value="assets.viewerUrl"
              />
            </a>
          </div>
          <p class="mt-4 flex justify-center text-highlight"><a :href="assets.viewerUrl" target="_blank">🔗 {{ $t('ecommerceStoryline.ar.qr.cta-link') }} 🔗</a></p>
        </PopoverPlate>
      </PopoverGroup>

      <div class="claim-container" :style="`left: ${3469-rightCorrection}px;`">
        <div class="logo">
          <a href="/" target="_blank" title="My AR Studio website">
            <img src="~/assets/img/logo-wt.svg">
          </a>
        </div>
        <div class="text">
          <p v-html="$t('ecommerceStoryline.vClaim')"/>
          <a v-if="showLink" href="/" target="_blank" title="My AR Studio website" >myarstudio.cloud</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import QrcodeVue from 'qrcode.vue'
import PopoverPlate, {Placement} from '~/components/storyline/PopoverPlate.vue';
import { PopoverGroup } from '@headlessui/vue'

import SpringChairBkg from '~/assets/img/ecommerce-storyline/bkg.webp'
import SpringChairImage from '~/assets/img/ecommerce-storyline/photo.webp'
import SpringChairCarousel from '~/assets/img/ecommerce-storyline/carousel.mp4'
import SpringChairPhoto360 from '~/assets/img/ecommerce-storyline/360.mp4'
import SpringChairViewer3D from '~/assets/img/ecommerce-storyline/3d-viewer.mp4'
import SpringChairAR from '~/assets/img/ecommerce-storyline/ar.mp4'
import SpringChairQR from '~/assets/img/ecommerce-storyline/qr-code-spring-chair.webp'

import BraunBkg from '~/assets/img/ecommerce-storyline/braun-bkg.webp'
import BraunImage from '~/assets/img/ecommerce-storyline/braun-photo.webp'
import BraunCarousel from '~/assets/img/ecommerce-storyline/braun-carousel.mp4'
import BraunPhoto360 from '~/assets/img/ecommerce-storyline/braun-360.mp4'
import BraunViewer3D from '~/assets/img/ecommerce-storyline/braun-3d-viewer.mp4'
import BraunAR from '~/assets/img/ecommerce-storyline/braun-ar.mp4'
import BraunQR from '~/assets/img/ecommerce-storyline/braun-qr.png'

const smallScreen = ref(false)
const stdPlacement = ref(Placement.bottom)

onMounted( ()=> {
  if(import.meta.client) {
    smallScreen.value = window.innerWidth <= 768
    stdPlacement.value = smallScreen.value ? Placement.bottom : Placement.top
  }
})

// TODO: duplicated component and page

// we have 2 background options with a different width 
// all scenes are equal except for the AR one that has a different width
// changing the AR scene also changes the cyan final scene
// year labels are right aligned so they are calculated from the right on the bigger bkg and adjusted with a computed correction
const defaulBkgWidth = 4030
const rightCorrection = computed(() => {
  return defaulBkgWidth - assets.value.bkgWidth
})

const allAssets: {
  [key:string] : {
    bkgWidth: number,
    arWidth: number,
    bkg: string,
    image: string
    carousel: string
    photo360: string
    viewer3d: string
    ar: string
    qr: string
    viewerUrl: string
    viewerLinkTitle: string
  }
} = {
  springChair: {
    bkgWidth: 4030,
    arWidth: 540,
    bkg: SpringChairBkg,
    image: SpringChairImage,
    carousel: SpringChairCarousel,
    photo360: SpringChairPhoto360,
    viewer3d: SpringChairViewer3D,
    ar: SpringChairAR,
    qr: SpringChairQR,
    viewerUrl: 'https://viewer.myarstudio.cloud/viewer_master/index.html?p=55c33d9f-6bee-507e-bcbc-bb03754a030e&c=f18af829-384e-52cc-9942-660c61ded0fd&b=f2f01834-6984-4e4d-bd20-8e7586523cf0&x-qr=1',
    viewerLinkTitle: 'Spring Chair 3D-AR Viewer'
  },
  braun: {
    bkgWidth: 3780,
    arWidth: 270,
    bkg: BraunBkg,
    image: BraunImage,
    carousel: BraunCarousel,
    photo360: BraunPhoto360,
    viewer3d: BraunViewer3D,
    ar: BraunAR,
    qr: BraunQR,
    viewerUrl: 'https://viewer.myarstudio.cloud/viewer_master/index.html?p=Braun%20KS20%20v2&c=Arancio&b=f2f01834-6984-4e4d-bd20-8e7586523cf0',
    viewerLinkTitle: 'Braun KF20 3D-AR Viewer'
  },
}

// Computed property to select the video set based on the prop
const assets = computed(() => {
  return allAssets[props.subject];
});

const props = defineProps({
  subject: { type: String, default: "springChair" },
  showLink: { type: Boolean, default: true },
})

</script>

<style scoped>
p {
  /* color: var(--color-darkgray); */
  color: #414244;
}
.slider {
  width: 100%;
  height: 596px;
  overflow-x: auto;
}
.bkg {
  height: 596px;
  position: relative;
}
.text-history {
  position: absolute;
  top: 93px;
  left: 86px;
  width: 180px;
  height: 242px;
  display: flex;
  flex-direction: column-reverse;
}
.text-history >>> p {
  font-size: 25px;
  line-height: 1.2;
  font-weight: 400;
}
span.nobr {
  white-space: nowrap;
}
.label {
  font-size: 19px;
  font-weight: 700;
  position: absolute;
}
.label-light p {
  color: #c5c1bf;
}
.label-y {
  top: 386px;
}

.label-photo {
  left: 668px;
}
.img-base {
  position: absolute;
  top: 75px;
  width: 270px;
  height: 270px;
}
.img-photo {
  left: 668px;
}
.img-carousel {
  left: 1205px;
}
.label-carousel {
  left: 1205px;
}

.img-360 {
  left: 1741px;
}
.label-360 {
  left: 1741px;
  line-height: 1;
}
.img-viewer-3d {
  left: 2285px;
}
.label-3d-viewer {
  left: 2285px;
  line-height: 1;
}

.img-ar {

  height: 377px;
  left: 2838px;
  top: 76px;
}
.label-ar {
  top: 442px;
  left: 2760px;
}


.claim-container {
  position: absolute;
  top: 0;
}
.claim-container .logo {
  position: absolute;
  top: 60px;
  left: 80px;
  width: 120px;
}
.claim-container .logo img {
  width: 100%;
  height: auto;
}
.claim-container .text {
  position: absolute;
  top: 230px;
  left: 95px;
  width: 410px;
}

.claim-container .text p {
  font-size: 37px;
  font-weight: 300;
  line-height: 1.2;
  @apply text-white;
}
.claim-container .text a {
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  @apply text-white hover:underline mt-4;
}

</style>
